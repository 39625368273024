import { get, put,Delete,post } from '../axios'

// 商品品牌-列表
export const getBrandList = params => get(`/goods/web/goodBrand/list`,params);
// 平台分类列表
export const listClassify = params => get(`/goods/web/goodsCategory/all/list`,params);
// 商品管理-规格-子规格列表
export const listSpecChild = params => get(`/goods/web/spec/goods/spec/child/list/${params}`);
// 商品管理-规格-列表
export const listSpec = params => get(`/goods/web/spec/goods/spec/list`,params);
//商品管理-根据商品详情查询规格列表
export const storeGoodsSpec = params => get(`/goods/web/spec/storeGoods/list/${params}`);
//商品管理-(平台)根据商品详情查询规格列表
export const goodsSpec = params => get(`/goods/web/spec/goods/list/${params}`);
// 供应商-新增
export const add = params => post(`/goods/web/goods/supplier/add`,params);
// 供应商-查看
export const view = params => get(`/goods/web/goods/view/${params}`);
// 供应商-编辑
export const update = params => put(`/goods/web/goods/supplier/update`,params);
// 供应商-分页列表
export const list = params => get(`/goods/web/goods/supplier/pageList`,params);
// 供应商-删除列表
export const deleteNew = params => Delete(`/goods/web/goods/supplier/delete/${params}`);
// 供应商-商品导入
export const exportData = params => post(`/goods/web/goods/supplier/importGoods`,params);
// 供应商-商品上下架
export const updateState = params => put(`/goods/web/goods/supplier/shelve`,params);
// 供应商-商品预览
export const goodsPreview = params => get(`/goods/web/goods/supplier/goods/preview/${params}`);

// export const getTemplateList = params => get(`/web/goods/manage/add/freight/template/list`,params);

