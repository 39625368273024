<template>
  <div class="win-zhezhao" v-if="details" @click="closeView">
  <div class="phone" @click.stop="" v-if="details.bannerImg">
    <el-carousel class="swiperBox">
      <el-carousel-item class="swiperBox" v-for="item in details.bannerImg.split(',')" :key="item">
        <img
          class="swiperBox"
          :src="item"
        />
      </el-carousel-item>
    </el-carousel>

    <div class="mine">
      <!-- 价格 -->
      <div class="price_box">
        <div class="price_name">{{details.price_name}}</div>
        <div class="price">
          <span>￥</span>{{details.originalPrice}} 
          <!-- <span class="discountRatio">123折</span> -->
        </div>
        <!-- <div class="price_old">￥1231</div> -->
      </div>
      <!-- 名称 -->
      <div class="good_name"><span>{{details.name}}</span> 12312</div>
      <div class="stock">
        <div>已售：<span>{{details.saleNum}}</span></div>
        <div>库存：<span>{{details.stock}}</span></div>
      </div>
    </div>

    <div class="msg">
      <!-- <div class="msg_item">
        <div class="msg_left">
          <div class="msg_name">限购数量</div>
          <div class="msg_con">
            <div>1231</div>
          </div>
        </div>
      </div> -->
      <!-- <div class="msg_item">
        <div class="msg_left">
          <div class="msg_name">购买条件</div>
          <div class="msg_con">121</div>
          <div class="msg_con">23423</div>
          <div class="msg_con">男</div>
        </div>
      </div> -->
      <div class="msg_item">
        <div class="msg_left">
          <div class="msg_name">是否预售</div>
          <div class="msg_con">{{details.isPreSale==0?'否':'是'}}</div>
        </div>
      </div>
      <div class="msg_item"  v-if="details.isPreSale==1">
        <div class="msg_left">
          <div class="msg_name">发货周期</div>
          <div class="msg_con">{{details.sendDays}}天</div>
        </div>
      </div>
    </div>

    <div class="msg">
			<div  class="msg_item">
				<div class="msg_left">
					<div class="msg_name">商品规格</div>
					<div class="msg_con_more">
						<div class="msg_con">12312</div>
						<!-- <image class="more" src="/static/images/icon/info_more.png" mode=""></image> -->
					</div>
				</div>
			</div>
			<div class="msg_item">
				<div class="msg_left">
					<div class="msg_name">商品品牌</div>
					<div class="msg_con">{{details.specNames}}</div>
				</div>
			</div>
			<div class="msg_item" v-if="details.service">
				<div class="msg_left">
					<div class="msg_name">商品服务</div>
					<div class="msg_con">{{details.service}}</div>
				</div>
			</div>
			<div  class="msg_item" v-if="details.freightType">
				<div class="msg_left">
					<div class="msg_name">发货方式</div>
					<div class="msg_con">{{details.freightType==1?'包邮':details.freightType==2?'固定运费':'运费模版'}}</div>
				</div>
			</div>
		</div>

    		<!-- 富文本 -->
		<div class="mine details">
			<div v-html="details.content||''"></div>
		</div>
  </div>
</div>
</template>
<script>
import {
  goodsPreview
} from "@/api/supplierGoods/goods";
export default {
  name: "goodsdiv",
  props:{
    goodsId:''
  },
  data() {
    return {
      details:{}
    };
  },
  mounted() {
   console.log('1231',this.goodsId)
   if(this.goodsId){
     this.getGoodsView()
   }
  },
  methods: {
    closeView(){
      this.$emit('closeView')
    },
    getGoodsView(){
      goodsPreview(this.goodsId).then((res) => {
          if (res.code === 200) {
            this.details = res.data
          } 
        });
    }
  },
};
</script>

<style scoped lang="scss">
.win-zhezhao{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone {
  width: 375px;
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
  background-color: #F5F6F9;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
.swiperBox {
  width:100%;
  height: 375px;
}

.mine {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffffff;
  .price_box {
    display: flex;
    align-items: baseline;
    .price_name {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #e93323;
    }
    .price {
      color: #e93323;
      font-weight: bold;
      font-size: 21px;
      margin-right: 16px;
      span {
        font-size: 12px;
      }
      .discountRatio {
        margin-left: 5px;
      }
    }
    .price_old {
      font-size: 26rpx;
      color: #666666;
      text-decoration: line-through;
    }
  }
  .good_name {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
    line-height: 17.5px;
    text {
      width: 34px;
      height: 20px;
      padding: 2.5px 5px;
      // display: inline-block;
      // line-height:40rpx ;
      text-align: center;
      margin-right: 10px;
      background-color: #e93323;
      font-size: 16px;
      color: #ffffff;
      border-radius: 2.5px;
    }
    // color: #ffffff;
  }
  .stock {
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #858585;
    // text{
    // 	color: #333333;
    // 	font-weight: bold;
    // }
  }
}

.msg{
	width: 100%;
	padding: 0 10px;
	background-color: #ffffff;
	margin-top: 10px;
  box-sizing: border-box;
	.msg_item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 44px;
		line-height: 44px;
		border-bottom:#F5F6F9 solid 1px;
		.msg_left{
			// width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 13px;
			.msg_name{
				width: 65px;
				font-family: PingFang SC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: #999999;
			}
			.msg_con{
				font-family: PingFang SC, PingFang SC;
				font-weight: 400;
				font-size: 14px;
				color: #000000;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.msg_con_more{
				width: 290px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.more{
					width: 20px;
					height: 20px;
				}
			}
			.evaluate{
				font-weight: bold;
			}
			.info_more{
				width: 20px;
				height: 20px;
			}
		}
		.msg_left_evaluate{
			width: 100%;
		}
	}
}
.details{
	margin-top: 20rpx;
  /deep/img{
    width: 100%;
  }
}
</style>
